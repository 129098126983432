<template>
    <div class="model_list" @scroll="handleScroll">
        <dialog-view ref="myDialog" @userBehavior="changeData"></dialog-view>
        <!-- <div id="pos"></div> -->
        <!-- <div class="notVideo" v-if="list.length === 0">
            <div class="not_video_back"></div>
            <div class="server_text" v-if="shootRecordState">您还没有拍摄记录~</div>
        </div> -->
        <div class="scroll-div">
            <!-- <div scroll-y="true" class="scroll-div" lower-threshold="30" @scrolltolower="scrollModeltolow"> -->
            <div>
                <div class="uni-title uni-common-mt list-title">{{$t('modelListTitle')}}</div>
                <div class="model_box" v-if="list_is_show">
                    <label v-for="(item, index) in list" :key="item.value" class="uni-list-cell uni-list-cell-pd">
                        <!-- <label class="uni-list-cell uni-list-cell-pd list" v-for="(item, index) in 3" :key="item.value"> -->
                        <div :class="item.pid == pid?'list-active-back p-t-b pos_father':'p-t-b marbotm pos_father'">
                            <div class="list-flex">
                                <div>
                                    <van-checkbox v-model="item.pid == pid"
                                        @click="changeDefaultModel(item)"></van-checkbox>
                                </div>
                                <div class="list_timer_felx">
                                    <p
                                        :class="item.modelUrl==''?'not_active_color':item.pid == pid?'list-active-color p1':'p1'">
                                        {{$t('captureTime')}}：{{item.shootTime}}
                                    </p>
                                    <p :class="item.pid == pid?'list-active-color p1':'p1'">
                                        {{$t('captureLocation')}}：{{item.shootShop}}</p>
                                </div>
                                <div class="list-delete-flex" @click.stop="openDeleteModel(item)">
                                    <img class="list-delete" src="@/assets/img/delete.png" mode=""></img>
                                    <div class="in-blo delete-color">{{$t('delete')}}</div>
                                </div>
                                <!-- @click.stop 阻止点击事件冒泡 -->
                                <!-- <div class="list-btn" @click.stop="openModel(item)">预览</div> -->
                            </div>
                        </div>
                        <!-- <div>{{item.name}}</div> -->
                    </label>
                </div>
            </div>
        </div>
        <div class="log_off" @click="openlogOffPop">{{$t('isLogOff')}}</div>
    </div>
</template>

<script>
    import dialogView from "@/components/dialogView.vue"
    import { Toast } from "vant";
    import "vant/lib/index.css";
    import throttle from "@/utils/common.js"
    export default {
        data() {
            return {
                list: [],
                phone: null,
                idx: "",
                list_is_show: true,
                isState: false,
                modelUrl: "",
                modelState: null,
                items: {},
                qqqqqq: false,
                modelPage: 1,
                pageLimit: 10,
                pid: '',
                title: '',
                shootRecordState: false,
                isBut: false
            }
        },
        created() {
            this.initList()
        },
        mounted() {
            
        },
        methods: {
            // 打开注销弹框
            openlogOffPop() {
                this.$refs.myDialog.show(this.$t('logOffTitle'), {
                    type: 'timer',
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    titleText: '',
                    data: { type: 'logOff', operate: 'exchange' }
                })
            },
            // 注销
            async logOffAccount() {
                const res = await this.$http.post('userLogout')
                if (res.success) {
                    Toast(this.$t('logOffSuccess'))
                } else {
                    Toast(this.$t('logOffError'))
                }
            },
            // 删除模型
            async deleteModel(pid) {
                console.log(pid)
                let data = {
                    email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
                    phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : '',
                    pid: pid
                }
                const res = await this.$http.post("shoot/deleteData", data)
                console.log(res)
                if (res.success) {
                    if (this.$i18n.locale == 'zh') {
                        Toast(this.$t('deleteSuccess'))
                        this.initList()
                    } else {
                        Toast.fail(this.$t('deleteSuccess'))
                    }
                }
            },
            // 确定弹框事件
            changeData(type, data) {
                if (type === 'clickConfirm') {
                    if (data.type === 'delete') { // 删除模型
                        // console.log("delete")
                        console.log(data.pid)
                        this.deleteModel(data.pid)
                    } else if (data.type === 'changeModel') { // 更改默认模型
                        // console.log("changeModel")
                        this.changeUserModel(data)
                    } else if (data.type === 'logOff') { // 注销账户
                        console.log('logOff')
                        // this.logOffAccount()
                    }
                }
            },
            // 更改默认模型
            async changeUserModel(itm) {
                let data = {
                    language: this.$i18n.locale,
                    id: itm.id,
                    pid: itm.pid,
                    isDefaults: !itm.isDefault
                }
                console.log(data)
                const res = await this.$http.post("shoot/setDefaultModel", data)
                console.log(res)
                if (res.success) {
                    this.pid = itm.pid
                    this.$router.go(-1)
                    // this.initList()
                }
            },
            async changeDefaultModel(item) {
                if (item.pid == this.pid) {
                    return
                }
                this.$refs.myDialog.show(this.$t('isChangeModel'), {
                    type: 'confirm',
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    titleText: '',
                    data: { type: 'changeModel', operate: 'exchange', pid: item.pid, id: item.id }
                })
                // console.log(item)

            },
            handleScroll: throttle(async function (e) {


                const { scrollTop, clientHeight, scrollHeight } = e.target
                // console.log(scrollTop, clientHeight, scrollHeight)
                // console.log(scrollTop + clientHeight)
                // console.log(scrollHeight)
                let data;
                if (scrollHeight - (scrollTop + clientHeight) < 60) {
                    this.modelPage += 1
                    if (localStorage.getItem('h5_user_info')) {
                        data = {
                            language: this.$i18n.locale,
                            email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
                            phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : '',
                            currentPage: this.modelPage,
                            pageLimit: this.pageLimit
                        }
                    }
                    // console.log(this.modelPage)
                    let res = await this.$http.post('shoot/getShootListData', data)
                    if (res.success) {
                        if (res.data.total > this.list.length) {
                            console.log(res)
                            res.data.dataList.forEach(item => {
                                if (item.isDefault) {
                                    this.pid = item.pid
                                    console.log(this.pid)
                                }
                                this.list.push(item)
                            })
                            console.log(this.list)
                        } else {
                        }
                    } else {
                        Toast(this.$t('getShootError'))
                    }
                }
            }, 200),
            // 删除模型
            openDeleteModel(item) {
                this.$refs.myDialog.show(this.$t('deleteModelText'), {
                    type: 'confirm',
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    titleText: '',
                    data: { type: 'delete', operate: 'exchange', pid: item.pid }
                })
            },
            async initList() {
                // this.phone = uni.getStorageSync("mobile")
                let data
                this.modelPage = 1
                if (localStorage.getItem('h5_user_info')) {
                    data = {
                        language: this.$i18n.locale,
                        email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
                        phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : '',
                        currentPage: this.modelPage,
                        pageLimit: this.pageLimit
                    }
                }
                let res = await this.$http.post('shoot/getShootListData', data)
                if (res.success) {
                    if (res.data.dataList.length === 0) {
                        this.shootRecordState = true
                    }
                    console.log(res.data.dataList);
                    this.list = res.data.dataList;
                    this.isBut = true
                    res.data.dataList.forEach(item => {
                        if (item.isDefault) {
                            this.pid = item.pid
                            console.log(this.pid)
                        }
                    })
                } else {
                    this.isBut = true
                }
                // console.log(res.data.dataList)
            },
        },
        watch: {
            // 监听list数组
            list(newVal) {
                // 判断list是否需要重新渲染 重新插入 解决v-for数据不更新问题
                // this.list_is_show = false
                if (true) {
                    // $nextTick 是在 DOM 更新循环结束之后执行延迟回调
                    this.$nextTick(() => {
                        // this.list_is_show = true
                    })
                }
            }
        },
        components: {
            dialogView,
        }
    }
</script>

<style scoped>
    .model_list {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
    }

    .model_box {
        width: 100%;
        height: 100%;
    }

    .notVideo {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        z-index: 999;
    }

    .not_video_back {
        width: 150px;
        height: 150px;
        /* background-image: url("../../static/img/state/not_pay_history.png"); */
        background-size: cover;
        margin: 180px 0 40px 0;
    }

    .list-delete-flex {
        width: 40px;
        height: 40px;
        margin: auto 15px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        right: 0px;
        top: 50%;
        margin-top: -20px;
    }

    .in-blo {
        display: inline-block;
    }

    .delete-color {
        color: rgba(153, 153, 153, 1);
        width: 100%;
        font-size: 14px;
        text-align: center;
    }

    .list-delete {
        width: 15px;
        height: 15px;
    }

    .server_text {
        height: 70px;
        line-height: 70px;
        width: 100%;
        text-align: center;
        color: #000;
        font-size: 14px;
    }

    .scroll-div {
        /* width: 100vw;
        height: 100vh; */
        min-height: 80vh;
        background-color: var(--primary-back-color);
    }


    .list-title {
        color: #E79102;
        width: 90%;
        margin: 0 auto;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
    }

    .list_timer_felx {
        margin-left: 16px;
        width: 254px;
    }

    .p-t-b {
        padding: 5px 0;
    }

    .list:nth-child(1) {
        width: 100%;
        /* background-color: #ffffff; */
    }

    .uni-list-cell {
        height: 109px;
    }

    .list-flex {
        width: 90%;
        margin: 10px auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: start;
    }

    .list-active-back {
        background-color: #ffffff;
    }

    .pos_father {
        position: relative;
    }

    .log_off {
        width: 82px;
        height: 28px;
        font-size: 16px;
        margin: 50px auto;
        padding: 2px 3px;
        text-align: center;
        line-height: 28px;
        opacity: 1;
        border-radius: 2px;
        border: 1px solid rgba(50, 59, 108, 1);
    }

    .list-active-color {
        color: rgba(0, 103, 255, 1) !important;
    }

    .list-btn {
        width: 60px;
        height: 30px;
        background-color: #0067FF;
        color: #fff;
        text-align: center;
        line-height: 30px;
        border-radius: 4px;
        font-size: 14px;
    }

    .p1 {
        font-size: 16px;
        padding-bottom: 5px;
        color: rgba(50, 59, 108, 1);
    }

    .not_active_color {
        font-size: 16px;
        padding-bottom: 5px;
        color: rgba(153, 153, 153, 1);
    }

    .not_active_color_p2 {
        font-size: 14px;
        padding-bottom: 5px;
        color: rgba(153, 153, 153, 1);
    }

    .p2 {
        color: rgba(50, 59, 108, 1);
        font-size: 14px;
        padding-bottom: 5px;
    }
</style>